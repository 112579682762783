import React, {FunctionComponent, useEffect, useState} from 'react';

import {Table, Flex, Typography, ConfigProvider} from 'antd';
import {EResponseError} from 'Core/Enums';
import dayjs from 'dayjs';
import {observer} from 'mobx-react';
import qs from 'qs';
import InfiniteScroll from 'react-infinite-scroll-component';
import useStores from 'Stores';

import s from './styles.module.scss';
import {useAuth} from '../../../Services/Auth';

const {Column} = Table;
const TRANSACTIONS_LIMIT: number = 25;

const ProfileTransactions: FunctionComponent = () => {
    const {modelsOptions, transactions} = useStores().profileStore;
    const {getTransactions} = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [limit, setLimit] = useState<number>(TRANSACTIONS_LIMIT);

    const formatDate = (isoString: string) => dayjs(isoString).format('DD.MM.YYYY HH:mm');

    const initiatTransactions = async () => {
        setIsLoading(true);
        try {
            const params = qs.stringify({offset: 0, limit});
            await getTransactions(params);
        } catch (e) {
            setError(EResponseError.SERVICE_UNAVAIBLE);
        } finally {
            setIsLoading(false);
        }
    };

    const loadMoreTransactions = async () => {
        if (isLoading) return;

        setIsLoading(true);
        try {
            const newLimit = limit + TRANSACTIONS_LIMIT;
            const params = qs.stringify({offset: 0, limit: newLimit});
            await getTransactions(params);
            setLimit(newLimit);
        } catch (e) {
            setError(EResponseError.SERVICE_UNAVAIBLE);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        void initiatTransactions();
        return () => {
            setError('');
            setLimit(TRANSACTIONS_LIMIT);
        };
    }, []);

    return (
        <Flex vertical justify="center" align="center">
            <Typography.Title level={2} className={s.title}>
                История транзакций
            </Typography.Title>
            {transactions && (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#2f80ed',
                        },
                    }}
                >
                    <div id="scrollableDiv" className={s.scrollContainer}>
                        <InfiniteScroll
                            dataLength={transactions.transactions?.length}
                            next={loadMoreTransactions}
                            hasMore={transactions.transactions?.length < transactions.total_count}
                            scrollableTarget="scrollableDiv"
                            loader={isLoading}
                        >
                            <Table
                                dataSource={transactions.transactions}
                                bordered
                                loading={isLoading}
                                pagination={false}
                            >
                                <Column
                                    title="Тип операции"
                                    dataIndex="account_transaction_type"
                                    key="account_transaction_type"
                                    align="center"
                                    width={50}
                                    render={(type: string) => {
                                        const isDeposit = type === 'deposit';
                                        const text = isDeposit ? 'Пополнение' : 'Списание';
                                        return (
                                            <Typography.Text style={{color: isDeposit ? 'green' : 'red'}}>
                                                {text}
                                            </Typography.Text>
                                        );
                                    }}
                                />
                                <Column
                                    title="Монеты"
                                    dataIndex="amount"
                                    key="amount"
                                    align="center"
                                    width={30}
                                    render={(amount: number, record) => {
                                        const isDeposit = record.account_transaction_type === 'deposit';
                                        return (
                                            <Typography.Text style={{color: isDeposit ? 'green' : 'red'}}>
                                                {isDeposit ? `+${amount}` : `-${amount}`}
                                            </Typography.Text>
                                        );
                                    }}
                                />
                                <Column
                                    title="Модель"
                                    dataIndex="model_name"
                                    key="model_name"
                                    align="center"
                                    width={30}
                                    render={(modelName: string) => {
                                        const model = modelsOptions.find(({name}) => name === modelName);
                                        return model ? model.title : modelName;
                                    }}
                                />
                                <Column
                                    title="Дата и время"
                                    dataIndex="create_time"
                                    key="create_time"
                                    align="center"
                                    width={50}
                                    render={(date: string) => formatDate(date)}
                                />
                            </Table>
                        </InfiniteScroll>
                    </div>
                </ConfigProvider>
            )}
            {error && (
                <Typography.Title level={4} className={s.errorText}>
                    {error}
                </Typography.Title>
            )}
        </Flex>
    );
};

export default observer(ProfileTransactions);
