import {FunctionComponent} from 'react';

import {Layout, Row, Button, Typography} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {ReactComponent as LogoIcon} from 'Assets/svg/logo.svg';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';

const PasswordRestoreConfirm: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <Layout className={s.layout}>
            <Content className={s.content}>
                <Row style={{width: '100%'}} justify="center">
                    <LogoIcon width="100" height="100" />
                </Row>
                <Row justify="center">
                    <Typography.Title className={s.text}>AI Rabbit</Typography.Title>
                </Row>
                <Row justify="center">
                    <Typography.Text className={s.text}>
                        Ссылка со сбросом пароля отправлена на ваш email
                    </Typography.Text>
                </Row>
                <Row style={{paddingTop: 20}} justify="center">
                    <Button
                        type="primary"
                        className={s.button}
                        onClick={() => navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH)}
                    >
                        Вернуться
                    </Button>
                </Row>
            </Content>
        </Layout>
    );
};

export default observer(PasswordRestoreConfirm);
