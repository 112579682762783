import React, {ChangeEvent, FC} from 'react';

import {FormOutlined, LeftOutlined, RightOutlined, SearchOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Input, Space, Typography, Flex} from 'antd';
import {ReactComponent as LogoIcon} from 'Assets/svg/logo.svg';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import Chats from '../MenuChats';
import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {usePrompts} from '../../../Services/Prompts';
import useStores from '../../../Stores';

const Menu: FC = () => {
    const navigate = useNavigate();
    const {resetMessages, setIsLoading, isSidebarOpen, setIsSidebarOpen, modifier} = useStores().searchStore;
    const {setPrompt} = useStores().promptStore;
    const {setCurrentHistoryChatId, setCurrentHistoryChatTitle} = useStores().historyStore;
    const {userInfo} = useStores().profileStore;
    const {getHistory} = usePrompts();

    const handleOnChangeChatSearch = async (e: ChangeEvent<HTMLInputElement>) => {
        await getHistory(e.target.value);
    };

    return (
        <>
            <Flex
                className={`${s.drawerMenu} ${!isSidebarOpen ? s.hidden : ''}`}
                vertical
                align="center"
                justify="space-between"
            >
                <Space direction="vertical" className={s.customHeader}>
                    <Flex gap="small" align="center" className={s.controls}>
                        <Button
                            size="large"
                            type="default"
                            icon={<LeftOutlined />}
                            shape="circle"
                            onClick={() => {
                                setIsSidebarOpen(false);
                            }}
                        />
                        <Button
                            size="large"
                            type="default"
                            iconPosition="end"
                            shape="round"
                            className={s.newChatBtn}
                            onClick={() => {
                                setIsLoading(false);
                                resetMessages();
                                setPrompt(null);
                                setCurrentHistoryChatId(null);
                                setCurrentHistoryChatTitle(null);
                                if (modifier === 'Mobile') {
                                    setIsSidebarOpen(false);
                                }
                                navigate(ROUTES.APP.PATH);
                            }}
                        >
                            Новый чат
                        </Button>
                    </Flex>
                    <Input
                        className={s.navSearchInput}
                        size="large"
                        placeholder="Поиск чата"
                        prefix={<SearchOutlined className={s.navSearchInputIcon} />}
                        onChange={handleOnChangeChatSearch}
                    />
                </Space>
                <Chats />
                <div>
                    {!userInfo?.is_business && <div className={s.balance}>Баланс: {userInfo?.user_balance}</div>}
                    <Button
                        size="large"
                        type="default"
                        shape="round"
                        className={s.profile}
                        onClick={() => {
                            if (modifier === 'Mobile') {
                                setIsSidebarOpen(false);
                            }
                            navigate(ROUTES.PROFILE.PATH);
                        }}
                    >
                        Перейти к профилю
                    </Button>
                    {!(modifier === 'Mobile' || modifier === 'LargeMobile') && (
                        <Flex align="end" justify="center" gap="middle" className={s.logo}>
                            <LogoIcon />
                            <Typography.Title className={s.title}>AI Rabbit</Typography.Title>
                        </Flex>
                    )}
                </div>
            </Flex>
            <Flex
                vertical
                align="center"
                justify="space-between"
                className={`${s.siderClosedWrapper} ${isSidebarOpen ? s.hidden : ''}`}
            >
                <Flex className={s.siderTopContainer} vertical align="center" justify="center" gap="middle">
                    <Button
                        size="large"
                        type="default"
                        icon={<RightOutlined />}
                        shape="circle"
                        onClick={() => {
                            setIsSidebarOpen(true);
                        }}
                    />
                    <Button
                        size="large"
                        type="default"
                        icon={<FormOutlined />}
                        shape="circle"
                        className={s.newChatBtn}
                        onClick={() => {
                            setIsLoading(false);
                            resetMessages();
                            setPrompt(null);
                            setCurrentHistoryChatId(null);
                            setCurrentHistoryChatTitle(null);
                            // setIsSidebarOpen(false);
                            navigate(ROUTES.APP.PATH);
                        }}
                    />
                </Flex>
                <Flex className={s.siderBottomContainer} vertical gap="middle">
                    <Button
                        size="large"
                        type="default"
                        icon={<UserOutlined />}
                        shape="circle"
                        onClick={() => navigate(ROUTES.PROFILE.PATH)}
                    />
                    <LogoIcon />
                </Flex>
            </Flex>
        </>
    );
};

export default observer(Menu);
