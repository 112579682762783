export const textPriceParser = (text: string): {text: string; price: string} => {
    // Регулярное выражение для поиска всех значений 'content'
    const contentRegex = /content='(.*?)'/g;
    let contentMatch;
    const contentValues: string[] = [];

    // Извлечение всех значений 'content'
    while ((contentMatch = contentRegex.exec(text)) !== null) {
        contentValues.push(contentMatch[1]);
    }

    // Объединение всех значений 'content' в одно сообщение
    const contentMessage = contentValues.join('');

    // Регулярное выражение для поиска всех значений 'price'
    const priceRegex = /price='(.*?)'/g;
    let priceMatch;
    let lastPrice = '';

    // Извлечение последнего значения 'price'
    while ((priceMatch = priceRegex.exec(text)) !== null) {
        lastPrice = priceMatch[1];
    }

    return {text: contentMessage, price: lastPrice.split('.')[0]};
};

export function parsingErrorMessage(response: string) {
    response = response.trim();
    const match = response.match(/{"detail":"(.*?)"}/);
    return match ? match[1] : response;
}
