export const AI_MODELS = {
    openai_text: [
        'gpt-3.5-turbo',
        'gpt-4',
        'gpt-4-1106-preview',
        'gpt-4o',
        'o3-mini',
        'gpt-4o-mini',
        'o1-preview',
        'o1-mini',
    ],
    openai_image: ['dall-e-3', 'dall-e-2'],
    stable_diffusion: ['text-to-image'],
    yandex: ['yandexgpt-lite', 'yandexgpt', 'summarization'],
    deepseek: ['deepseek-chat'],
};
