import {FunctionComponent, useEffect} from 'react';

import {observer} from 'mobx-react';

import {EPageTitle} from '../../../Core/Enums';
import Profile from '../../Components/Profile';

const ProfilePage: FunctionComponent = () => {
    useEffect(() => {
        document.title = EPageTitle.PROFILE;
    }, []);

    return <Profile />;
};

export default observer(ProfilePage);
