import {action, makeAutoObservable, observable} from 'mobx';

import {IBalanceAiModel, IModelOption, IUserInfo} from './types';
import Auth from '../../Api/Auth';

export default class ProfileStore {
    @observable
    isUserInfoLoading: boolean = false;

    @observable
    userInfo: IUserInfo | null = null;

    @observable
    email: string = '';

    // TODO: переделать логику, нужно проверять количество оставшихся по каждой модели запросов
    @observable
    hasSubscription = true;

    @observable
    transactions: any = null;

    @observable
    availablePacks: any = [];

    @observable
    modelsOptions: IModelOption[] = [];

    @observable
    modelsRequests: IBalanceAiModel[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    api = new Auth();

    @action
    setIsUserInfoLoading = (isUserInfoLoading: boolean): void => {
        this.isUserInfoLoading = isUserInfoLoading;
    };

    @action
    setUserInfo = (userInfo: IUserInfo): void => {
        this.userInfo = userInfo;
    };

    @action
    setEmail = (email: string): void => {
        this.email = email;
    };

    @action
    setTransactions = (transactions: any): void => {
        this.transactions = transactions;
    };

    @action
    setAvailablePacks = (packs: any[]): void => {
        this.availablePacks = packs;
    };

    @action
    setSubscriptionStatus = (value: boolean): void => {
        this.hasSubscription = value;
    };

    @action
    setModelsOptions = (models: any): void => {
        this.modelsOptions = models;
    };

    @action
    setAiModelsRequests = (value: any) => {
        this.modelsRequests = value;
    };
}
