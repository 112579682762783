import {useNavigate} from 'react-router-dom';

import AiDisruptApi from '../../Api/AiDisrupt';
import {ICreatePromptPayload} from '../../Api/AiDisrupt/types';
import ROUTES from '../../Core/Const/Routes';
import {EHTTPStatus} from '../../Core/Enums';
import useStores from '../../Stores';
import {usePromptsListStore, usePromptStore, useSearchStore} from '../Adapters/store';

// eslint-disable-next-line  @typescript-eslint/explicit-function-return-type
export const usePrompts = () => {
    const api: AiDisruptApi = new AiDisruptApi();
    const {setMessages, setIsChatHistoryLoading, removeMessages} = useStores().searchStore;
    const {setPrompts} = usePromptsListStore();
    const {setChats} = useStores().historyStore;

    const {
        setPrompt,
        setMessageWords,
        savingPromptTitle,
        savingPromptDescription,
        savingPromptTemplate,
        resetSavingPromptData,
        prompt,
    } = usePromptStore();
    const {messages, currentChatId} = useSearchStore();
    const navigate = useNavigate();

    return {
        async getPrompts(searchPhrase?: string) {
            try {
                const response = await api.fetchPrompts(searchPhrase);
                setPrompts(response.data);
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    const response = await api.fetchPrompts();
                    setPrompts(response.data);
                    return response;
                }
            }
        },
        async getPrompt(id: number) {
            try {
                const response = await api.fetchPrompt(id);
                setPrompt(response.data);
                setMessageWords();
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    const response = await api.fetchPrompt(id);
                    setPrompt(response.data);
                    setMessageWords();
                }
            }
        },
        async createPrompt() {
            const payload: ICreatePromptPayload = {
                title: savingPromptTitle,
                description: savingPromptDescription,
                template: savingPromptTemplate,
                is_public: true,
                categories: [],
            };

            try {
                const promptId = await api.createPrompt(payload);
                resetSavingPromptData();
                navigate(`${ROUTES.PROMPTS.PATH}/${promptId}`);
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    const promptId = await api.createPrompt(payload);
                    resetSavingPromptData();
                    navigate(`${ROUTES.PROMPTS.PATH}/${promptId}`);
                }
            }
        },

        async updatePrompt(id: number) {
            const payload: ICreatePromptPayload = {
                title: prompt?.title ?? '',
                description: prompt?.description ?? '',
                template: prompt?.template ?? '',
                is_public: true,
                categories: [],
            };

            try {
                await api.updatePrompt(id, payload);
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    await api.updatePrompt(id, payload);
                }
            }
        },

        async deletePrompt(id: number) {
            try {
                return await api.deletePrompt(id);
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    return await api.deletePrompt(id);
                }
            }
        },
        async saveChat(promptId?: number) {
            const payload = {
                id: currentChatId,
                prompt_id: promptId,
                messages,
            };

            try {
                await api.saveChat(payload);
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    await api.saveChat(payload);
                }
            }
        },
        async updateChat() {
            const payload = {messages};
            try {
                await api.updateChat(currentChatId, payload);
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    await api.updateChat(currentChatId, payload);
                }
            }
        },
        async saveHistory(promptId?: number) {
            const mappedMessages = messages.map((message) => ({
                rank: message.rank,
                role: message.role,
                content: message.content,
                price: message.price,
                mode: message.mode,
                like: message.like,
                dislike: message.dislike,
            }));

            const payload = {
                prompt_id: promptId,
                title: messages[0].content,
                description: '',
                messages: mappedMessages,
            };
            return await api.saveHistoryChat(payload);
        },
        async getHistory(searchPhrase?: string) {
            try {
                const chats = await api.getHistory(searchPhrase);
                setChats(chats);
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    const chats = await api.getHistory(searchPhrase);
                    setChats(chats);
                }
            }
        },
        async getHistoryChat(id: number, isOpened?: boolean) {
            try {
                if (!isOpened) {
                    setIsChatHistoryLoading(true);
                    const {messages} = await api.getHistoryChat(id);
                    setMessages(messages);
                    setIsChatHistoryLoading(false);
                } else {
                    const {messages} = await api.getHistoryChat(id);
                    setMessages(messages);
                }
            } catch (e: any) {
                if (e.response.status === EHTTPStatus.UNAUTHORIZED) {
                    const {messages} = await api.getHistoryChat(id);
                    setMessages(messages);
                }
                setIsChatHistoryLoading(false);
            }
        },
        async updateHistoryChat(chatId: number) {
            const mappedMessages = messages.map((message) => ({
                rank: message.rank,
                role: message.role,
                content: message.content,
                price: message.price,
                mode: message.mode,
                like: message.like,
                dislike: message.dislike,
            }));
            const payload = {title: messages[0].content, messages: mappedMessages};
            await api.updateHistoryChat(chatId, payload);
        },
        async updateHistoryChatWithRank(message: any, chatId: number | null) {
            if (chatId != null) {
                await api.updateHistoryChatWithRank(chatId, message.rank);
                removeMessages(message.rank);
            }
        },
        async deleteChatFromHistory(chatId: number) {
            await api.deleteChatFromHistory(chatId);
        },
    };
};
