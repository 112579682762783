import {
    PACK_ENDPOINT,
    AUTH_LOGIN_CHANGE_ENDPOINT,
    AUTH_LOGIN_INDIVIDUAL_ENDPOINT,
    AUTH_LOGIN_BUSINESS_ENDPOINT,
    AUTH_LOGOUT_ENDPOINT,
    AUTH_PASSWORD_CHANGE_ENDPOINT,
    AUTH_PASSWORD_RESTORE_ENDPOINT,
    AUTH_PASSWORD_RESET_ENDPOINT,
    AUTH_TOKEN_REFRESH_ENDPOINT,
    USER_AI_MODEL_ENDPOINT,
    USER_AI_MODEL_REQUESTS_ENDPOINT,
    USER_ENDPOINT,
    AUTH_VERIFY_ACCESS_TOKEN_ENDPOINT,
    getPackPaymentEndpoint,
    getYandexLoginEndpoint,
    getGoogleLoginEndpoint,
    getVkLoginEndpoint,
    getRegistrationEndpoint,
    getResendEmailConfirmationEndpoint,
    getTransactionsEndpoint,
} from '../../Core/Const/Endpoints';
import {getRefreshTokenHeader} from '../../Services/LocalStorage';
import {authClient, client} from '../axios';
import {IAiModelsResponse} from './types';

export default class Auth {
    loginIndividual = async (payload: any): Promise<any> => {
        return await authClient.post(AUTH_LOGIN_INDIVIDUAL_ENDPOINT, payload);
    };

    loginBusiness = async (payload: any): Promise<any> => {
        return await authClient.post(AUTH_LOGIN_BUSINESS_ENDPOINT, payload);
    };

    loginYandex = async (code: string, redirectUri: string): Promise<any> => {
        return await authClient.get(getYandexLoginEndpoint(code, redirectUri));
    };

    loginGoogle = async (code: string, redirectUri: string): Promise<any> => {
        return await authClient.get(getGoogleLoginEndpoint(code, redirectUri));
    };

    loginVk = async (payload: any): Promise<any> => {
        return await authClient.get(getVkLoginEndpoint(payload));
    };

    logout = async (): Promise<any> => {
        return await authClient.post(AUTH_LOGOUT_ENDPOINT);
    };

    changeLogin = async (newLogin: string): Promise<any> => {
        return await authClient.put(AUTH_LOGIN_CHANGE_ENDPOINT, {
            new_login: newLogin,
        });
    };

    changePassword = async (payload: any): Promise<any> => {
        return await authClient.put(AUTH_PASSWORD_CHANGE_ENDPOINT, payload);
    };

    registration = async (payload: any, successUri: string): Promise<any> => {
        return await authClient.post(getRegistrationEndpoint(successUri), payload);
    };

    passwordRestore = async (payload: any): Promise<any> => {
        return await authClient.post(AUTH_PASSWORD_RESTORE_ENDPOINT, payload);
    };

    passwordReset = async (payload: any): Promise<any> => {
        return await authClient.post(AUTH_PASSWORD_RESET_ENDPOINT, payload);
    };

    resendEmailConfirmation = async (payload: any, successUri: string): Promise<any> => {
        return await authClient.post(getResendEmailConfirmationEndpoint(successUri), payload);
    };

    getUser = async (): Promise<any> => {
        return await authClient.get(USER_ENDPOINT);
    };

    getTransactions = async (params: any): Promise<any> => {
        return await authClient.get(getTransactionsEndpoint(params));
    };

    getAvailablePacks = async (): Promise<any> => {
        return await authClient.get(PACK_ENDPOINT);
    };

    verifyAccessToken = async (): Promise<any> => {
        return await authClient.post(AUTH_VERIFY_ACCESS_TOKEN_ENDPOINT);
    };

    refreshTokens = async (): Promise<any> => {
        return await client.post(AUTH_TOKEN_REFRESH_ENDPOINT, null, {
            headers: {Authorization: getRefreshTokenHeader()},
        });
    };

    getAiModels = async (): Promise<any> => {
        return await authClient.get(USER_AI_MODEL_ENDPOINT);
    };

    getPackPaymentLink = async (packId: string): Promise<any> => {
        return await authClient.post(getPackPaymentEndpoint(packId));
    };

    getAiModelsRequests = async (): Promise<IAiModelsResponse> => {
        return await authClient.get(USER_AI_MODEL_REQUESTS_ENDPOINT);
    };
}
