import {FunctionComponent, useEffect, useState} from 'react';

import {Button, Card, Col, Flex, Row, Typography} from 'antd';
import {observer} from 'mobx-react';

import s from './styles.module.scss';
import {headers} from '../../../Api/axios';
import memoizedRefreshToken from '../../../Api/refresh';
import {PAYMENT_PROVIDER_ENDPOINT} from '../../../Core/Const/Endpoints';
import {BACKEND_SERVICE_URL} from '../../../Core/Const/Env';
import ROUTES from '../../../Core/Const/Routes';
import {
    getAccessToken,
    getAccessTokenHeader,
    isAccessTokenExpired,
    isTokensExist,
} from '../../../Services/LocalStorage';

interface IPackage {
    amount: number;
    title: string;
    name: string;
}

const extendedHeaders = async (): Promise<any> => {
    const extendedHeaders: any = headers;

    if (isTokensExist()) {
        if (isAccessTokenExpired()) {
            await memoizedRefreshToken();
        }

        if (getAccessToken()) {
            extendedHeaders.Authorization = getAccessTokenHeader();
        }
    }

    return extendedHeaders;
};

const ProfilePackages: FunctionComponent = () => {
    const [packages, setPackages] = useState<IPackage[]>([
        {amount: 500, title: '...', name: '500 рублей'},
        {amount: 1000, title: '...', name: '1000 рублей'},
        {amount: 5000, title: '...', name: '5000 рублей'},
    ]);

    const getConvertedPrice = async (amount: number): Promise<string> => {
        try {
            const response = await fetch(
                `${BACKEND_SERVICE_URL}${PAYMENT_PROVIDER_ENDPOINT}converted_price?price=${amount}`,
                {
                    method: 'GET',
                    headers: await extendedHeaders(),
                }
            );
            if (response.ok) {
                const data = await response.json();
                return `${data} монет`;
            }
            console.error('Ошибка при получении конвертированной цены', await response.json());
            return `${amount} монет`;
        } catch (error) {
            console.error('Ошибка при запросе конвертированной цены:', error);
            return `${amount} монет`;
        }
    };

    useEffect(() => {
        const fetchPrices = async () => {
            const updatedPackages = await Promise.all(
                packages.map(async (pack) => ({
                    ...pack,
                    title: await getConvertedPrice(pack.amount),
                }))
            );
            setPackages(updatedPackages);
        };

        void fetchPrices();
    }, []);

    const handleTopUp = async (amount: number) => {
        const redirectUri = `redirect_uri=${window.location.origin}${ROUTES.PROFILE.PATH}`;
        const TopUpRedirectUrl = `${BACKEND_SERVICE_URL}${PAYMENT_PROVIDER_ENDPOINT}payment_link?payment_amount=${amount}&${redirectUri}`;

        const response = await fetch(TopUpRedirectUrl, {
            method: 'GET',
            headers: await extendedHeaders(),
        });
        if (response.ok) {
            const data = await response.json();
            window.location.replace(data);
        } else {
            console.error('Ошибка при получении ссылки на оплату', await response.json());
        }
    };

    return (
        <Flex vertical justify="center" gap="large" className={s.wrapper}>
            <Typography.Title level={2} className={s.title}>
                Выберите сумму пополнения
            </Typography.Title>
            <Row className={s.cardsContainer}>
                {packages.map((pack) => (
                    <Col key={pack.amount}>
                        <Card className={`${s.card}`}>
                            <Flex vertical gap="middle" align="center">
                                <Typography.Title level={3} className={s.packageName}>
                                    {pack.title}
                                </Typography.Title>
                                <Typography.Title level={2} className={s.amount}>
                                    {pack.name}
                                </Typography.Title>
                                <Button
                                    type="primary"
                                    className={s.button}
                                    onClick={async () => await handleTopUp(pack.amount)}
                                >
                                    Пополнить
                                </Button>
                            </Flex>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Flex>
    );
};

export default observer(ProfilePackages);
