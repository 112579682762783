import {OPENAI_CHAT_ENDPOINT, OPENAI_TEXT_TO_IMAGE_ENDPOINT} from '../../Core/Const/Endpoints';
import {authClient} from '../axios';
import {IChatMessageBackend, IOpenaiTextToImageRequest, IOpenaiTextToImageResponse} from './types';

export default class OpenaiAPI {
    sendChatMessage = async (messages: IChatMessageBackend[]): Promise<any> => {
        return await authClient.post(OPENAI_CHAT_ENDPOINT, {messages});
    };

    textToImage = async (
        payload: IOpenaiTextToImageRequest,
        modelName: string
    ): Promise<IOpenaiTextToImageResponse> => {
        const response = await authClient.post(`${OPENAI_TEXT_TO_IMAGE_ENDPOINT}?model_name=${modelName}`, payload);
        return {imgUrl: response.data};
    };
}
