import React, {FunctionComponent, useEffect, useState} from 'react';

import {Layout, Row, Typography, Button} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {ReactComponent as LogoIcon} from 'Assets/svg/logo.svg';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {useAuth} from '../../../Services/Auth';
import useStores from '../../../Stores';

const RegistrationConfirm: FunctionComponent = () => {
    const {email} = useStores().profileStore;
    const {resendEmailConfirmation} = useAuth();
    const navigate = useNavigate();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [cooldown, setCooldown] = useState<number | null>(60);

    const handleResendEmailConfirmation = () => {
        const successUri = `success_uri=${window.location.origin}${ROUTES.UNAUTHORIZED.LOGIN.PATH}`;
        const payload = {email};
        void resendEmailConfirmation(payload, successUri);

        setIsButtonDisabled(true);
        setCooldown(60);
    };

    useEffect(() => {
        if (!email) {
            return navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
        }
    }, [email]);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (cooldown !== null && cooldown > 0) {
            timer = setInterval(() => {
                setCooldown((prev) => (prev !== null ? prev - 1 : null));
            }, 1000);
        } else if (cooldown === 0) {
            setIsButtonDisabled(false);
            setCooldown(null);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [cooldown]);

    return (
        <Layout className={s.layout}>
            <Content className={s.content}>
                <Row style={{width: '100%', paddingBottom: 30}} justify="center">
                    <LogoIcon width="100" height="100" />
                </Row>
                <Row justify="center">
                    <Typography.Title className={s.text}>Пожалуйста, подтвердите регистрацию</Typography.Title>
                </Row>
                <Row justify="center">
                    <Typography.Text className={s.text}>
                        Ссылка с подверждением отправлена на email {email}
                    </Typography.Text>
                </Row>
                <Row style={{paddingTop: 30}} justify="center">
                    <Typography.Title className={s.text}>AI Rabbit</Typography.Title>
                </Row>
                <Row style={{paddingTop: 30}} justify="center">
                    <Button
                        type="primary"
                        className={s.button}
                        onClick={handleResendEmailConfirmation}
                        disabled={isButtonDisabled}
                    >
                        {isButtonDisabled ? `Отправить повторно через ${cooldown} сек` : 'Отправить письмо еще раз'}
                    </Button>
                </Row>
                <Row style={{paddingTop: 20}} justify="center">
                    <Button
                        type="primary"
                        className={s.button}
                        onClick={() => navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH)}
                    >
                        Вернуться
                    </Button>
                </Row>
            </Content>
        </Layout>
    );
};

export default observer(RegistrationConfirm);
