import {FunctionComponent} from 'react';

import {Space, Typography, Flex} from 'antd';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import s from './styles.module.scss';

const {Text, Title} = Typography;

const ProfileSettingsInfo: FunctionComponent = () => {
    const {userInfo} = useStores().profileStore;

    const renderInfoField = (label: string, value: string | number | undefined | null) => {
        if (value === undefined || value === null) return null;

        return (
            <Space className={s.text}>
                <Text>{label}: </Text>
                <Text>{value}</Text>
            </Space>
        );
    };

    return (
        <Flex vertical={true} className={s.wrapper}>
            <Title className={s.title} level={4}>
                Информация
            </Title>
            {renderInfoField('Логин', userInfo?.login)}
            {renderInfoField('Email', userInfo?.email)}
            {userInfo?.is_business && renderInfoField('Организация', userInfo.user_group)}
            {!userInfo?.is_business && renderInfoField('Баланс', userInfo?.user_balance)}
        </Flex>
    );
};

export default observer(ProfileSettingsInfo);
