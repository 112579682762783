import {FunctionComponent, useEffect, useState} from 'react';

import {EyeInvisibleOutlined, EyeOutlined, LockOutlined} from '@ant-design/icons';
import {Button, Form, Input, Layout, Typography} from 'antd';
import {AxiosError} from 'axios';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {EPageTitle, EHTTPStatus, EResponseError} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';

interface IResetData {
    password: string;
    password_confirm: string;
}

const PasswordResetForm: FunctionComponent = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {resetPassword} = useAuth();
    const [resetErrors, setResetErrors] = useState<string[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        document.title = EPageTitle.PASSWORD_RESET;
    }, []);

    const handleResetPassword = async (resetData: IResetData) => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const payload = {
            confirmation_token: token,
            password: resetData.password,
            confirmed_password: resetData.password_confirm,
        };
        try {
            await resetPassword(payload);
            navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
        } catch (e) {
            const err = e as AxiosError;

            if (err.response?.status === EHTTPStatus.SERVER_ERROR) setResetErrors([EResponseError.SERVICE_UNAVAIBLE]);
        }
    };

    const onFinish = async (resetData: IResetData) => {
        await form.validateFields();
        await handleResetPassword(resetData);
    };

    const validateMessages = {
        required: 'Поле не может быть пустым',
    };

    const handleFormChange = () => {
        const values = form.getFieldsValue();
        const isValid = values.password && values.password_confirm && values.password === values.password_confirm;
        setIsButtonDisabled(!isValid);
        setResetErrors([]);
    };

    return (
        <Layout className={s.resetPage}>
            <Form
                form={form}
                className={s.resetForm}
                layout="vertical"
                onValuesChange={handleFormChange}
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Typography.Title className={s.title}>AI Rabbit</Typography.Title>
                <Form.Item className={s.formItem} name="password" rules={[{required: true}]}>
                    <Input.Password
                        className={s.antInput}
                        prefix={<LockOutlined className={s.icon} />}
                        placeholder="ПАРОЛЬ"
                        iconRender={(visible) =>
                            visible ? (
                                <EyeOutlined style={{color: '#ffffff'}} />
                            ) : (
                                <EyeInvisibleOutlined style={{color: '#ffffff'}} />
                            )
                        }
                    />
                </Form.Item>
                <Form.Item className={s.formItem} name="password_confirm" rules={[{required: true}]}>
                    <Input.Password
                        className={s.antInput}
                        prefix={<LockOutlined className={s.icon} />}
                        placeholder="ПОВТОРИТЕ ПАРОЛЬ"
                        iconRender={(visible) =>
                            visible ? (
                                <EyeOutlined style={{color: '#ffffff'}} />
                            ) : (
                                <EyeInvisibleOutlined style={{color: '#ffffff'}} />
                            )
                        }
                    />
                </Form.Item>
                <div className={s.errorList}>
                    <Form.ErrorList errors={resetErrors} />
                </div>
                <Form.Item className={s.formItem}>
                    <Button className={s.resetButton} type="primary" htmlType="submit" disabled={isButtonDisabled}>
                        Сменить
                    </Button>
                </Form.Item>
                <Button
                    type="primary"
                    className={s.backButton}
                    onClick={() => navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH)}
                >
                    Вернуться
                </Button>
            </Form>
        </Layout>
    );
};

export default observer(PasswordResetForm);
