import {FunctionComponent, useEffect, useState} from 'react';

import {CloseCircleOutlined} from '@ant-design/icons';
import {Button, Layout, Spin, Typography, ConfigProvider} from 'antd';
import {AxiosError} from 'axios';
import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import qs from 'qs';
import {useNavigate} from 'react-router';

import s from './styles.module.scss';
import {EHTTPStatus} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';

const AuthVk: FunctionComponent = () => {
    const {loginVk} = useAuth();
    const navigate = useNavigate();
    const [authError, setAuthError] = useState<string>('');

    const signInWithVk = async (payload: any) => {
        try {
            await loginVk(payload);
            navigate(ROUTES.APP.PATH, {replace: true});
        } catch (e) {
            const err = e as AxiosError;

            if (err.response?.status === EHTTPStatus.CONFLICT) {
                setAuthError('Ошибка авторизации, используйте альтернативный метод входа');
            } else {
                setAuthError('Что-то пошло не так, попробуйте позже');
            }
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const deviceId = params.get('device_id');
        const redirectUri = `${window.location.origin}${ROUTES.UNAUTHORIZED.LOGIN_VK.PATH}`;

        if (code && deviceId && state) {
            const payload = qs.stringify({code, state, device_id: deviceId, redirect_uri: redirectUri});
            void signInWithVk(payload);
        }
    }, []);

    return (
        <Layout className={s.authPage}>
            {authError ? (
                <>
                    <CloseCircleOutlined className={s.errorIcon} />
                    <Typography.Title level={3} className={s.errorText}>
                        {authError}
                    </Typography.Title>
                    <Button type="default" onClick={() => navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH)}>
                        Вернуться
                    </Button>
                </>
            ) : (
                <>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#ffffff',
                            },
                        }}
                    >
                        <Spin size="large" />
                    </ConfigProvider>
                    <Typography.Title level={3} className={s.loadText}>
                        Ждем ответ сервиса...
                    </Typography.Title>
                </>
            )}
        </Layout>
    );
};

export default observer(AuthVk);
