import {FunctionComponent, useEffect, useState} from 'react';

import {MailOutlined} from '@ant-design/icons';
import {Button, Form, Input, Layout, Typography} from 'antd';
import {AxiosError} from 'axios';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {EPageTitle, EHTTPStatus, EResponseError} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';

interface IRestoreData {
    email: string;
}

const PasswordRestoreForm: FunctionComponent = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {restorePassword} = useAuth();
    const [restoreErrors, setRestoreErrors] = useState<string[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        document.title = EPageTitle.PASSWORD_RESTORE;
    }, []);

    const handleRestorePassword = async (restoreData: IRestoreData) => {
        const successUri = `${window.location.origin}${ROUTES.UNAUTHORIZED.PASSWORD_RESET.PATH}`;
        const payload = {
            email: restoreData.email,
            success_uri: successUri,
        };
        try {
            await restorePassword(payload);
            navigate(ROUTES.UNAUTHORIZED.PASSWORD_RESTORE_CONFIRM.PATH);
        } catch (e) {
            const err = e as AxiosError;

            if (err.response?.status === EHTTPStatus.NOT_FOUND) setRestoreErrors(['Пользователь не найден']);

            if (err.response?.status === EHTTPStatus.SERVER_ERROR) setRestoreErrors([EResponseError.SERVICE_UNAVAIBLE]);
        }
    };

    const onFinish = async (restoreData: IRestoreData) => {
        await form.validateFields();
        await handleRestorePassword(restoreData);
    };

    const validateMessages = {
        required: 'Поле не может быть пустым',
        types: {
            email: 'Некорректный email',
        },
    };

    const handleFormChange = () => {
        const values = form.getFieldsValue();
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isValid = values.email && emailRegex.test(values.email || '');
        setIsButtonDisabled(!isValid);
        setRestoreErrors([]);
    };

    return (
        <Layout className={s.restorePage}>
            <Form
                className={s.restoreForm}
                form={form}
                layout="vertical"
                onValuesChange={handleFormChange}
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Typography.Title className={s.title}>Сброс пароля</Typography.Title>
                <Form.Item className={s.formItem} name="email" rules={[{type: 'email'}, {required: true}]}>
                    <Input
                        className={s.antInput}
                        prefix={<MailOutlined className={s.icon} />}
                        placeholder="email@example.com"
                    />
                </Form.Item>
                <div className={s.errorList}>
                    <Form.ErrorList errors={restoreErrors} />
                </div>
                <Form.Item className={s.formItem}>
                    <Button className={s.restoreButton} type="primary" htmlType="submit" disabled={isButtonDisabled}>
                        Восстановить
                    </Button>
                </Form.Item>
                <Button
                    type="primary"
                    className={s.backButton}
                    onClick={() => navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH)}
                >
                    Вернуться
                </Button>
            </Form>
        </Layout>
    );
};

export default observer(PasswordRestoreForm);
