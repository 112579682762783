import {FunctionComponent} from 'react';

import {Skeleton} from 'antd';
import {EProfileTabs} from 'Core/Enums';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import ProfilePackages from '../ProfilePackages';
import ProfileSettings from '../ProfileSettings';
import ProfileTransactions from '../ProfileTransactions';
import s from './styles.module.scss';

interface HeaderProfileProps {
    activeTab: EProfileTabs;
}

const ProfileContent: FunctionComponent<HeaderProfileProps> = ({activeTab}) => {
    const {isUserInfoLoading} = useStores().profileStore;
    const renderTabContent = () => {
        switch (activeTab) {
            case EProfileTabs.settings:
                return <ProfileSettings />;
            case EProfileTabs.tariffs:
                return <ProfilePackages />;
            case EProfileTabs.transactions:
                return <ProfileTransactions />;
        }
    };

    return (
        <div className={s.container}>
            {isUserInfoLoading ? (
                <Skeleton avatar paragraph={{rows: 10}} />
            ) : (
                <div className={s.tabContent}>{renderTabContent()}</div>
            )}
        </div>
    );
};

export default observer(ProfileContent);
