import React, {FunctionComponent, ReactNode, useEffect, useState} from 'react';

import {Layout} from 'antd';
import Sider from 'antd/es/layout/Sider';
import classNames from 'classnames';
import {getScreenModifier} from 'Core/Const/Breakpoints';
import {observer} from 'mobx-react';
import {useAuth} from 'Services/Auth';
import useStores from 'Stores';

import {usePrompts} from '../../../Services/Prompts';
import Menu from '../Menu';
import SubscriptionWarningModal from '../SubscriptionWarningModal';
import s from './styles.module.scss';

const {Header, Content, Footer} = Layout;

interface IProps {
    header?: ReactNode;
    children?: ReactNode;
    footer?: ReactNode;
}

const PageWrapper: FunctionComponent<IProps> = ({header, children, footer}) => {
    const {getHistory} = usePrompts();
    const {getUser} = useAuth();
    const {isSidebarOpen, modifier, setModifier} = useStores().searchStore;
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

    useEffect(() => {
        void getHistory();
        void getUser();
    }, []);

    useEffect(() => {
        const updateModifier = () => {
            const width = window.innerWidth;
            setModifier(getScreenModifier(width));
        };

        updateModifier();
        window.addEventListener('resize', updateModifier);

        return () => {
            window.removeEventListener('resize', updateModifier);
        };
    }, []);

    const handleModal = () => {
        setIsWarningModalOpen(false);
    };

    const calculatedCollapsedWidth = modifier === 'Mobile' ? 0 : 73;

    return (
        <Layout className={s.layout}>
            <Sider
                className={classNames(s.sider, modifier && s[`sider--${modifier}`])}
                collapsed={!isSidebarOpen}
                width={
                    modifier === 'Tablet'
                        ? 250
                        : modifier === 'LargeMobile'
                          ? 200
                          : modifier === 'Mobile'
                            ? '100%'
                            : 282
                }
                collapsedWidth={calculatedCollapsedWidth}
            >
                <Menu />
            </Sider>
            <Layout className={isSidebarOpen && modifier === 'Mobile' && s.mainLayoutInvisible}>
                {header ? <Header className={s.header}>{header}</Header> : null}
                <Content className={s.content}>
                    <div className={s.container}>{children}</div>
                </Content>
                {footer ? <Footer className={s.footer}>{footer}</Footer> : null}
                <SubscriptionWarningModal open={isWarningModalOpen} onOk={handleModal} onCancel={handleModal} />
            </Layout>
        </Layout>
    );
};

export default observer(PageWrapper);
