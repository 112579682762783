import StableDiffusionAPI from '../../Api/StableDiffusion';
import {EHTTPStatus, EResponseError} from '../../Core/Enums';
import {useSearchStore} from '../Adapters/store';

// eslint-disable-next-line  @typescript-eslint/explicit-function-return-type
export const useStableDiffusion = () => {
    const api = new StableDiffusionAPI();
    const {messages, setIsLoading, aiMode} = useSearchStore();

    return {
        async convertTextToImage(): Promise<any> {
            try {
                setIsLoading(true);
                const response = await api.textToImage({prompt: messages[messages.length - 1].content}, aiMode);
                setIsLoading(false);
                return response;
            } catch (e: any) {
                setIsLoading(false);
                if (e.status === EHTTPStatus.PAYMENT_REQUIRED) {
                    return EResponseError.PAYMENT_REQUIRED;
                } else {
                    return EResponseError.SERVICE_UNAVAIBLE;
                }
            }
        },
    };
};
