import React, {FunctionComponent, ReactNode, useEffect, useState} from 'react';

import {SendOutlined} from '@ant-design/icons';
import {Button, Image, Row, Select, Typography, Alert, Tooltip} from 'antd';
import classNames from 'classnames';
import {observer} from 'mobx-react';

import s from './styles.module.scss';
import {ResizeBreakpoints} from '../../../Core/Const/Breakpoints';
import {AnswerMode} from '../../../Core/Enums';
import {useSearchStore} from '../../../Services/Adapters/store';
import useStores from '../../../Stores';

interface IProps {
    children?: ReactNode;
    onClickSend: () => Promise<void>;
    resetControl: ReactNode;
}

type TModelName =
    | 'o1-preview'
    | 'o1-mini'
    | 'gpt-4'
    | 'gpt-4-1106-preview'
    | 'gpt-4o'
    | 'o3-mini'
    | 'gpt-4o-mini'
    | 'gpt-3.5-turbo'
    | 'dall-e-3'
    | 'dall-e-2'
    | 'yandexgpt-lite'
    | 'yandexgpt'
    | 'summarization'
    | 'text-to-image'
    | 'deepseek-chat';

const renderModelTitle = (title: string, name: TModelName) => {
    const mappedImg = {
        'o1-preview': require('Assets/svg/o1-preview.svg').default,
        'o1-mini': require('Assets/svg/o1-mini.svg').default,
        'gpt-4': require('Assets/svg/gpt-4.svg').default,
        'gpt-4-1106-preview': require('Assets/svg/gpt-4-1106-preview.svg').default,
        'gpt-4o': require('Assets/svg/gpt-4o.svg').default,
        'o3-mini': require('Assets/svg/o3-mini.svg').default,
        'gpt-4o-mini': require('Assets/svg/gpt-4o-mini.svg').default,
        'gpt-3.5-turbo': require('Assets/svg/gpt-3.5-turbo.svg').default,
        'dall-e-3': require('Assets/svg/dall-e-3.svg').default,
        'dall-e-2': require('Assets/svg/dall-e-2.svg').default,
        'yandexgpt-lite': require('Assets/svg/yandexgpt-lite.svg').default,
        yandexgpt: require('Assets/svg/yandexgpt.svg').default,
        summarization: require('Assets/svg/yandex-sum.svg').default,
        'text-to-image': require('Assets/svg/text-to-image.svg').default,
        'deepseek-chat': require('Assets/svg/deepseek-logo.svg').default,
    };

    return (
        <>
            <Image className={s.modelsOptionsImage} src={mappedImg[name]} preview={false} />
            <Typography.Text className={s.modelsOptionsText}>{title}</Typography.Text>
        </>
    );
};

const InputWrapper: FunctionComponent<IProps> = ({children, onClickSend, resetControl}: IProps) => {
    const {isLoading, aiMode, setAiMode} = useSearchStore();
    const {modelsOptions, userInfo} = useStores().profileStore;
    const [modifier, setModifier] = useState<string | null>(null);
    const isDisabled = !userInfo?.user_balance;

    const mappedModelsOptions = modelsOptions.map(({name, title}) => ({
        label: renderModelTitle(title, name),
        value: name,
    }));

    const defineDefaultModel = (availableModels: TModelName[]): TModelName => {
        const modelPriority: TModelName[] = [
            AnswerMode.GPT_4_O_MINI,
            AnswerMode.GPT_4_O,
            AnswerMode.O1_PREVIEW,
            AnswerMode.O1_MINI,
            AnswerMode.O3_MINI,
            AnswerMode.GPT_4_PRO,
            AnswerMode.GPT_4,
            AnswerMode.GPT_3_5,
            AnswerMode.YANDEX_GPT_PRO,
            AnswerMode.YANDEX_GPT_LITE,
            AnswerMode.YANDEX_GPT_SUM,
            AnswerMode.DALLE_3,
            AnswerMode.DALLE_2,
            AnswerMode.TEXT_TO_IMAGE,
            AnswerMode.DEEPSEEK,
        ];

        const selectedModel = modelPriority.find((model) => availableModels.includes(model));
        return selectedModel ?? AnswerMode.GPT_4_O_MINI;
    };

    const getScreenModifier = (width: number): 'Mobile' | 'LargeMobile' | 'Tablet' | null => {
        if (width < ResizeBreakpoints.Mobile) {
            return 'Mobile';
        }
        if (width < ResizeBreakpoints.LargeMobile) {
            return 'LargeMobile';
        }
        if (width < ResizeBreakpoints.Tablet) {
            return 'Tablet';
        }
        return null;
    };

    const handleSendClick = async () => {
        const availableModels = modelsOptions.map(({name}) => name as TModelName);
        if (!availableModels.includes(aiMode as TModelName)) {
            alert('Вам необходимо пополнить баланс запросов к моделям.');
            return;
        }
        await onClickSend();
    };

    const renderAiModeSelector = (): React.ReactElement => {
        const {Option} = Select;

        if (mappedModelsOptions.length > 0) {
            return (
                <Select
                    value={aiMode}
                    variant="borderless"
                    suffixIcon={null}
                    className={s.modelSelector}
                    dropdownStyle={{width: 200}}
                    placeholder=""
                    defaultValue=""
                    onChange={(value) => setAiMode(value)}
                    virtual={false}
                >
                    {mappedModelsOptions.map((model) => (
                        <Option key={model.value} value={model.value}>
                            {model.label}
                        </Option>
                    ))}
                </Select>
            );
        } else {
            return (
                <Tooltip title="Для продолжения работы Вам необходимо пополнить баланс запросов к моделям">
                    <Alert message="Нет активного плана" type="info" showIcon />
                </Tooltip>
            );
        }
    };

    useEffect(() => {
        const availableModels = modelsOptions.map(({name}) => name as TModelName);
        const defaultModel = defineDefaultModel(availableModels);
        setAiMode(defaultModel);
    }, []);

    useEffect(() => {
        const updateModifier = () => {
            const width = window.innerWidth;
            setModifier(getScreenModifier(width));
        };

        updateModifier();
        window.addEventListener('resize', updateModifier);

        return () => {
            window.removeEventListener('resize', updateModifier);
        };
    }, []);

    return (
        <Row className={classNames(s.messageInput, modifier && s[`messageInput--${modifier}`])}>
            <Row className={s.inputWrapper} wrap={false} align="middle">
                {renderAiModeSelector()}
                {children}
                <Row className={s.sendBtnWrapper} justify="center">
                    <Button
                        loading={isLoading}
                        className={s.sendBtn}
                        type="text"
                        disabled={isDisabled}
                        icon={<SendOutlined className={s.sendBtnIcon} />}
                        onClick={handleSendClick}
                    />
                </Row>
            </Row>
        </Row>
    );
};

export default observer(InputWrapper);
