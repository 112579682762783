import {parsingErrorMessage, textPriceParser} from 'Services/utils';

import Streaming from '../../Api/stream';
import {ERole} from '../../Core/Enums';
import {useSearchStore} from '../Adapters/store';

// eslint-disable-next-line  @typescript-eslint/explicit-function-return-type
export const useDeepseekChat = () => {
    const streaming = new Streaming();
    const {
        messages,
        setIsLoading,
        appendTextToCurrentMessage,
        updatePriceOfCurrentMessage,
        setCurrentMessage,
        addMessage,
        aiMode,
        setIsAiAnswering,
    } = useSearchStore();

    return {
        async sendChatMessageInStreamingModeDeepseek() {
            setIsAiAnswering(true);

            const lastEndOfContextIndex = messages.map(({role}) => role).lastIndexOf(ERole.CONTEXT_END);
            const sendingMessages: Array<{role: string; content: string}> = messages
                .slice(lastEndOfContextIndex + 1)
                .map(({role, content}) => ({role, content}));

            if (sendingMessages.length === 0) {
                setIsAiAnswering(false);
                return;
            }

            setIsLoading(true);
            const sg = streaming.streamingGenerator(
                await streaming.sendStreamingMessageDeepseek(sendingMessages, aiMode)
            );

            // @ts-expect-error
            let response: IteratorResult<string> = {};
            setCurrentMessage(ERole.ASSISTANT, '', '', aiMode);
            addMessage();
            setIsLoading(false);

            do {
                response = await sg.next();

                if (response?.value) {
                    const {text, price} = textPriceParser(response.value);
                    if (!text) {
                        const openAIAnswer = parsingErrorMessage(response.value);
                        appendTextToCurrentMessage(openAIAnswer);
                    }
                    appendTextToCurrentMessage(text);
                    updatePriceOfCurrentMessage(price);
                }
            } while (response && !response.done);
            setIsAiAnswering(false);
        },
    };
};
